<template>
	<div class="page-box tool-equipment">
		<div class="topBox">
			<p class="page-title">设备管理</p>
			<!-- <el-button type="success" size="medium" @click="openDialog('表格')">新增</el-button> -->
			<div class="search-box">
				<el-select class="maring" v-model="searchInfo.project" placeholder="请选择项目" filterable clearable @change="searchData(4)">
					<el-option v-for="(item, index) in projectSelectList" :key="index" :label="item" :value="item"></el-option>
				</el-select>
				<el-input v-model="searchInfo.equipment" placeholder="请输入设备编号" clearable>
					<template slot="append"><el-button type="primary" icon="el-icon-search" @click="searchData(1)"></el-button></template>
				</el-input>
				<el-select class="maring" v-model="searchInfo.useStatus" placeholder="请选择使用状态" clearable @change="searchData(2)">
					<el-option v-for="(item, index) in useStatus" :key="index" :label="item" :value="index"></el-option>
				</el-select>
				<el-select v-model="searchInfo.calibrationStatus" placeholder="请选择标定状态" clearable @change="searchData(3)">
					<el-option v-for="(item, index) in calibrationStatus" :key="index" :label="item" :value="index"></el-option>
				</el-select>
			</div>
		</div>
		<div class="table-box scroll-style" id="prject-table">
			<el-table
				ref="table"
				:data="tableSearchData"
				stripe
				:border="true"
				:resizable="false"
				:summary-method="getSummaries"
				@row-click="openDrawer"
				:cell-style="cellStyle"
				:show-summary="summary"
				style="width: 100%; margin-top: 20px"
				:max-height="maxHeight"
			>
				<el-table-column type="index" label="序号" width="50"></el-table-column>
				<el-table-column prop="dno" label="仪器编号" width="150px"></el-table-column>
				<el-table-column prop="sno" label="传感器编号" align="center" width="120px"></el-table-column>
				<el-table-column prop="ste" label="标定状态" :formatter="formatterTable" align="center" width="135px"></el-table-column>
				<el-table-column prop="cno" label="标定编号" align="center" width="150px"></el-table-column>
				<el-table-column prop="term" label="标定日期" align="center" width="120px"></el-table-column>
				<el-table-column prop="status" label="使用状态" align="center" width="130px">
					<template slot-scope="scope">
						<el-tag v-show="formatterTable(scope.row, scope.column) == '使用中'" type="success" size="small">使用中</el-tag>
						<el-tag v-show="formatterTable(scope.row, scope.column) == '停用'" type="info" size="small">停用</el-tag>
						<el-tag v-show="formatterTable(scope.row, scope.column) == '空闲'" type="warning" size="small">空闲</el-tag>
						<el-tag v-show="formatterTable(scope.row, scope.column) == '故障'" type="danger" size="small">故障</el-tag>
					</template>
				</el-table-column>
				
				<el-table-column prop="pjname" label="项目"></el-table-column>
				<el-table-column prop="area" label="地域" :formatter="formatterTable"></el-table-column>
				<!-- <el-table-column label="操作" width="100">
					<template slot-scope="scope">
						<el-button type="text" size="small" @click="openDrawer(scope.row)">详情</el-button>
						<el-button type="text" size="small" @click="tableEditor(scope.row)">编辑</el-button>
					</template>
				</el-table-column> -->
			</el-table>
		</div>
		<div class="bottom-but"><el-button type="primary" plain @click="downloadTableExcel">下载Excel</el-button></div>
		<!-- 详情 -->
		<el-drawer :visible.sync="detailsDrawer" :with-header="true" size="1000px">
			<div slot="title">
				<span class="drawer-title">{{ detailsTitle }}</span>
				<el-dropdown trigger="click" @command="useStatusChange">
					<span class="el-dropdown-link">
						<el-tag class="pointer" v-if="useStatus[detailsInfo.status - 1] == '使用中'" type="success" size="small">使用中</el-tag>
						<el-tag class="pointer" v-if="useStatus[detailsInfo.status - 1] == '停用'" type="info" size="small">停用</el-tag>
						<el-tag class="pointer" v-if="useStatus[detailsInfo.status - 1] == '空闲'" type="warning" size="small">空闲</el-tag>
						<el-tag class="pointer" v-if="useStatus[detailsInfo.status - 1] == '故障'" type="danger" size="small">故障</el-tag>
						<i class="el-icon-arrow-down el-icon--right pointer"></i>
					</span>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item command="2">正常</el-dropdown-item>
						<el-dropdown-item command="1">故障</el-dropdown-item>
						<el-dropdown-item command="4">停用</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
			<div class="detail-content">
				<div class="top-but"><el-button class="certificate-but" type="primary" size="mini" plain @click="openEditor('标定')">标定</el-button></div>
				<div class="content-box">
					<div class="top-box">
						<el-descriptions title="" :column="1">
							<el-descriptions-item label="传感器编号">{{ detailsInfo.sno }}</el-descriptions-item>
						</el-descriptions>
						<el-descriptions title="" :column="3">
							<el-descriptions-item label="标定状态" :content-class-name="detailsInfo.ste == 1 ? '' : detailsInfo.ste == 2 ? 'noOverdue' : 'expired'">
								{{ calibrationStatus[detailsInfo.ste - 1] }}
							</el-descriptions-item>
							<el-descriptions-item label="标定编号">{{ detailsInfo.cno }}</el-descriptions-item>
							<el-descriptions-item label="标定日期">{{ detailsInfo.term }}</el-descriptions-item>
						</el-descriptions>
						<el-descriptions title="" :column="2">
							<el-descriptions-item label="地域">{{ formatterArea(detailsInfo.area) }}</el-descriptions-item>
							<el-descriptions-item label="项目">{{ detailsInfo.pjname }}</el-descriptions-item>
						</el-descriptions>
					</div>
					<div class="tabs-box">
						<el-tabs v-model="activeName" @tab-click="handleClick">
							<el-tab-pane label="使用日志" name="1">
								<div class="log-box">
									<div class="top-but-box"><el-button type="primary" size="mini" icon="el-icon-plus" plain @click="openDialog('日志')"></el-button></div>
									<div class="content">
										<vue-scroll>
											<el-steps direction="vertical" :space="100">
												<el-step v-for="(i, index) in logData" :key="index">
													<template slot="title">
														<div class="title">
															<div class="text">
																<p class="name text-overflow">{{ i.cdate }}</p>
																<!-- <p class="time">{{ i.type == 1 ? '' }}</p> -->
															</div>
															<div class="but-box">
																<el-button
																	v-show="i.pn"
																	type="primary"
																	size="mini"
																	icon="el-icon-picture-outline"
																	plain
																	@click="getLogImg(i)"
																></el-button>
															</div>
														</div>
													</template>
													<template slot="description">
														<div class="log-description" v-show="!i.info.hasOwnProperty('msg')">
															<el-descriptions title="" :column="2">
																<el-descriptions-item label="保管人员">{{ i.info.keepers }}</el-descriptions-item>
																<el-descriptions-item label="使用日期">{{ i.info.date }}</el-descriptions-item>
																<el-descriptions-item label="确认人员">{{ i.info.confirmPersonnel }}</el-descriptions-item>
																<el-descriptions-item label="设备状态">{{ i.info.equipmentState }}</el-descriptions-item>
															</el-descriptions>
															<el-descriptions title="" :column="1">
																<el-descriptions-item label="相关人员">{{ i.info.relevantPersonnel }}</el-descriptions-item>
																<el-descriptions-item label="使用原由">{{ i.info.reason }}</el-descriptions-item>
															</el-descriptions>
														</div>
														<div class="log-description" v-show="i.info.hasOwnProperty('msg')">
															<el-descriptions title="" :column="1">
																<el-descriptions-item label="内容">{{ i.info.msg }}</el-descriptions-item>
															</el-descriptions>
														</div>
													</template>
												</el-step>
											</el-steps>
										</vue-scroll>
									</div>
								</div>
							</el-tab-pane>
						</el-tabs>
					</div>
				</div>
			</div>
		</el-drawer>
		<!-- 新增列表 -->
		<el-dialog :title="tableAdd ? '新增表格' : '修改表格'" :visible.sync="dialogTable" width="500px">
			<el-form :model="tableForm">
				<el-form-item label="活动名称"><el-input v-model="tableForm.name" autocomplete="off"></el-input></el-form-item>
				<el-form-item label="活动区域">
					<el-select v-model="tableForm.region" placeholder="请选择活动区域">
						<el-option label="区域一" value="shanghai"></el-option>
						<el-option label="区域二" value="beijing"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogTable = false">取 消</el-button>
				<el-button type="primary" @click="dialogTable = false">确 定</el-button>
			</div>
		</el-dialog>
		<!-- 新增日志 -->
		<el-dialog :title="logAdd ? '新增日志' : '修改日志'" :visible.sync="dialogLog" custom-class="logDialog" width="480px" @close="colseDialog('日志')">
			<el-tabs v-model="logActiveName" @tab-click="logHandleClick">
				<el-tab-pane label="使用记录" name="2">
					<el-form :model="logForm" label-position="right" label-width="115px">
						<el-form-item label="保管人员"><el-input v-model="logForm.keepers" autocomplete="off"></el-input></el-form-item>
						<el-form-item label="使用日期" required>
							<el-date-picker v-model="logForm.date" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间"></el-date-picker>
						</el-form-item>
						<el-form-item label="使用原由" required><el-input v-model="logForm.reason" autocomplete="off"></el-input></el-form-item>
						<el-form-item label="设备状态"><el-input v-model="logForm.equipmentState" autocomplete="off"></el-input></el-form-item>
						<el-form-item label="相关人员"><el-input v-model="logForm.relevantPersonnel" autocomplete="off"></el-input></el-form-item>
						<el-form-item label="确认人员" required><el-input v-model="logForm.confirmPersonnel" autocomplete="off"></el-input></el-form-item>
						<el-form-item label="附件"><uploadImg ref="upload2" :configuration="configuration2" /></el-form-item>
					</el-form>
				</el-tab-pane>
				<el-tab-pane label="其他" name="1">
					<el-form :model="logForm" label-position="right" label-width="115px">
						<el-form-item label="日志内容" required><el-input v-model="logForm.msg" autocomplete="off"></el-input></el-form-item>
						<el-form-item label="附件"><uploadImg ref="upload1" :configuration="configuration1" /></el-form-item>
					</el-form>
				</el-tab-pane>
			</el-tabs>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogLog = false">取 消</el-button>
				<el-button type="primary" @click="addLog">确 定</el-button>
			</div>
		</el-dialog>
		<!-- 查看/编辑校准证书信息 -->
		<el-dialog title="校准证书信息" :visible.sync="dialogCalibration" custom-class="calibrationDialog" width="430px">
			<div class="pop-ups-content">
				<div class="cell">
					<span class="title">
						<i>*</i>
						校准证书编号
					</span>
					<el-input type="number" class="number" placeholder="请输入" @blur="automaticallyFill" v-model="certificate.serialNumber">
						<template slot="prepend">
							校准字第
						</template>
						<template slot="append">
							号
						</template>
					</el-input>
				</div>
				<div class="cell">
					<span class="title">
						<i>*</i>
						有效期
					</span>
					<el-input type="number" class="tvalidity" placeholder="" v-model="certificate.years">
						<template slot="append">
							年
						</template>
					</el-input>
					<el-input type="number" class="tvalidity" placeholder="" v-model="certificate.month">
						<template slot="append">
							月
						</template>
					</el-input>
					<el-input type="number" class="tvalidity" placeholder="" v-model="certificate.day">
						<template slot="append">
							日
						</template>
					</el-input>
				</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogCalibration = false">取 消</el-button>
				<el-button type="primary" :disabled="certificate.serialNumber == '' || certificate.periodOfValidity == ''" @click="editorCertificateInformation">确 定</el-button>
			</div>
		</el-dialog>
		<!-- 弹窗打开后关闭显示图标 -->
		<div class="open-icon" v-show="detailsId.toString() != ''"><span class="el-icon-s-fold" @click="iconClick"></span></div>
		<!-- 大图预览 -->
		<el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="srcList" />
	</div>
</template>

<script>
import equipment from './equipment.js';
export default equipment;
</script>

<style scoped lang="scss">
@import './equipment.scss';
</style>
