import data from "./equipmentData";
import uploadImg from "../../../components/uploadImg.vue";
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';
export default {
	name: '',
	data,
	created() {
		this.getTable()
	},
	mounted() {},
	computed: {},
	methods: {
		// 查询表格数据
		getTable() {
			this.projectSelectList = [];
			this.axios.get('web2/app/db/ghod/glist').then((res) => {
				this.summary = false;
				this.tableData = [];
				this.projectSelectList = [];
				var arr = [];
				if (res.status) {
					res.data.forEach((i) => {
						i.equipmentnum = 1;
						arr.push(i);
						if(!this.projectSelectList.includes(i.pjname) && i.pjname){
							this.projectSelectList.push(i.pjname);
						}
					})
					
					this.tableData = arr;
					this.searchData();
				}
			})
		},
		// 查询日志
		getLogData() {
			var data = {
				id: this.detailsId
			};
			this.axios.post('web2/app/db/ghod/gdml', data).then((res) => {
				this.logData = [];
				if (res.status) {
					res.data.forEach((i, index) => {
						i.info = {};
						if (i.type == 2) {
							let val = i.msg.split('│');
							val.forEach((item, ind) => {
								i.info[this.logTitle[ind]] = item;
							})
						}else {
							i.info.msg = i.msg;
						}
						this.logData.push(i)
					})
					// console.log(this.logData)
				}
			})
		},
		// 获取日志图片
		getLogImg(row) {
			var val = {
				id: this.detailsId,
				name: row.pn
			};
			this.showViewer = false;
			this.srcList = [];
			this.axios.post('web2/app/db/ghod/gdlp', val, {
				responseType: 'arraybuffer'
			}).then(res => {
				// // console.log(res);
				this.showViewer = false;
				this.srcList = [];
				const data = res;
				var headersName = res.headers['content-disposition'];

				if (res.headers['content-type'].split(';')[0] != 'application/json') {
					if (headersName != undefined) {
						this.fileName = decodeURIComponent(escape(headersName.split('=')[1]));
					}
					// data 是返回来的二进制数据
					var blob = new Blob([data], {
						type: 'image/png'
					});
					var href = window.URL.createObjectURL(blob);
					this.srcList = [href];
					this.showViewer = true;
				} else {
					// arraybuffer转字符串
					let resBlob = new Blob([res]);
					let reader = new FileReader();
					reader.readAsText(resBlob, 'utf-8');
					reader.onload = () => {
						var type = typeof JSON.parse(reader.result);
						if (type == 'string') {
							this.$message({
								message: reader.result,
								type: 'error'
							});
						} else if (type == 'object') {
							let res = JSON.parse(reader.result);
							this.$message({
								message: res.message,
								type: 'error'
							});
						}
					};
				}
			});
		},
		// 新增日志
		addLog() {
			// var data = {
			// 	id: this.detailsId,
			// 	type: Number(this.logActiveName),
			// 	msg: ,
			// 	file: ''
			// };
			var data = new FormData();
			data.append('id', this.detailsId);
			data.append('type', Number(this.logActiveName));
			// data.append('file', this.detailsId);
			if (this.logActiveName == '1') {
				if (this.logForm.msg.toString() == '') {
					this.$message({
						showClose: true,
						message: '请填写内容',
						type: 'warning'
					});
					return
				}
				data.append('msg', this.logForm.msg);
				let fileList = this.$refs.upload1.getFile();
				fileList.forEach((i) => {
					data.append("file", i.raw);
				})
			} else if (this.logActiveName == '2') {
				let txt = '';
				for (let k in this.logForm) {
					if (k != 'msg') {
						if (k == 'confirmPersonnel' || k == 'date' || k == 'reason') {
							if (this.logForm[k] === '') {
								this.$message({
									showClose: true,
									message: '请将必填项填写完整',
									type: 'warning'
								});
								return
							}
						}
						txt = txt + this.logForm[k] + '│'
					}
				}
				data.append('msg', txt.substring(0, txt.length - 1));
				let fileList = this.$refs.upload2.getFile();
				fileList.forEach((i) => {
					data.append("file", i.raw);
				})
			}
			this.axios.post('web2/app/db/uma/adlg', data).then((res) => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '添加成功！',
						type: 'success'
					});
					this.dialogLog = false;
					this.getLogData();
				}

			})
		},
		// 更改使用状态
		useStatusChange(command) {
			var data = {
				id: this.detailsId,
				status: command
			}
			this.axios.post('web2/app/db/uma/mdus', data).then((res) => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '更改成功',
						type: 'success'
					});
					if (command != 2) {
						this.detailsInfo.status = command;
					} else {
						if (this.detailsInfo.pid == 0) {
							this.detailsInfo.status = 2;
						} else {
							this.detailsInfo.status = 3;
						}
					}
					this.getTable();
				}
			})
		},
		// 编辑设备校准证书信息
		editorCertificateInformation() {
			for (let k in this.certificate) {
				if (this.certificate == "") {
					this.$message({
						showClose: true,
						message: '请将信息填写完整',
						type: 'warning'
					});
				}
			}
			var data = {
				id: this.certificate.id,
				cno: this.certificate.serialNumber,
				term: this.certificate.years + "/" + this.certificate.month + "/" + this.certificate.day,
			}
			this.axios.post('web2/app/db/uma/vtem', data).then((res) => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '修改成功！',
						type: 'success'
					});
					this.detailsInfo.term = data.term;
					this.detailsInfo.cno = data.cno;
					this.getTable();
					this.dialogCalibration = false;
				}
			})
		},
		// 设备校准证书编号填写自动填写有效期
		automaticallyFill() {
			var data = this.certificate.serialNumber;
			if (data) {
				this.certificate.years = Number(data.slice(0, 4)) + 1;
				this.certificate.month = data.slice(4, 6);
			}
		},
		// 查询条件改变函数
		searchData(val = '') {
			if (val == 1) {
				this.searchInfo.equipmentNumber = this.searchInfo.equipment;
				this.searchInfo.useStatus = null;
				this.searchInfo.calibrationStatus = null;
				this.searchInfo.project = '';
			} else if (val == 2) {
				this.searchInfo.equipmentNumber = '';
				this.searchInfo.equipment = '';
				this.searchInfo.calibrationStatus = null;
				this.searchInfo.project = '';
			} else if (val == 3) {
				this.searchInfo.useStatus = null;
				this.searchInfo.equipmentNumber = '';
				this.searchInfo.equipment = '';
				this.searchInfo.project = '';
			} else if (val == 4) {
				this.searchInfo.calibrationStatus = null;
				this.searchInfo.useStatus = null;
				this.searchInfo.equipmentNumber = '';
				this.searchInfo.equipment = '';
			}
			this.summary = false;

			this.tableSearchData = this.tableData.filter((item) => {
				if (this.searchInfo.equipmentNumber != '') {
					if (item.dno.indexOf(this.searchInfo.equipmentNumber) != -1) {
						return item
					}
				} else if (this.searchInfo.useStatus != null && this.searchInfo.useStatus.toString() !=
					'') {
					if (item.status == this.searchInfo.useStatus + 1) {
						return item
					}
				} else if (this.searchInfo.calibrationStatus != null && this.searchInfo.calibrationStatus
					.toString() !=
					'') {
					if (item.ste == this.searchInfo.calibrationStatus + 1) {
						return item
					}
				} else if (this.searchInfo.project !== '') {
					if (item.pjname.indexOf(this.searchInfo.project) != -1) {
						return item
					}
				} else {
					return item
				}
			})
		},
		// Excel表下载
		downloadTableExcel() {
			// // console.log(this.tableSummary)
			// return
			var data = [
				['序号', '仪器编号', '传感器编号', '标定状态', '标定编号', '标定日期', '使用状态', '项目', '地域']
			]; //表格标题
			var width = [8, 15, 15, 100, 15, 15, 100, 80, 60]; //表格每列宽度
			var arr = [];
			let ind = 0;
			this.tableSearchData.forEach((i, index) => {
				arr.push({
					id: index + 1,
					index: index + 1,
					dno: i.dno,
					sno: i.sno,
					ste: this.calibrationJudge(i.ste),
					cno: i.cno,
					term: i.term,
					status: this.useStatus[i.status - 1],
					pjname: i.pjname,
					area: this.formatterArea(i.area),
				})
				ind = index + 1;
			})
			arr.push({
				id: ind + 1,
				index: this.tableSummary[0],
				dno: this.tableSummary[1],
				sno: this.tableSummary[2],
				ste: this.tableSummary[3],
				cno: this.tableSummary[4],
				term: this.tableSummary[5],
				status: this.tableSummary[6],
				pjname: this.tableSummary[7],
				area: this.tableSummary[8],
			})
			data.push(arr);
			// // console.log(arr);
			var name = '设备列表';
			this.tableDownloadExcel(data, name, width);
		},
		// 打开新增弹窗
		openDialog(val) {
			if (val == '表格') {
				this.tableAdd = true;
				this.dialogTable = true;
			} else if (val == '日志') {
				this.logAdd = true;
				this.dialogLog = true;
			}
		},
		// 打开编辑弹窗
		openEditor(val) {
			if (val == '表格') {
				this.tableAdd = false;
				this.dialogTable = true;
			} else if (val == '日志') {
				this.logAdd = false;
				this.dialogLog = true;
			} else if (val == '标定') {
				this.certificate.id = this.detailsId;
				this.certificate.serialNumber = this.detailsInfo.cno;
				this.automaticallyFill();
				this.dialogCalibration = true;
			}
		},
		// 关闭弹窗
		colseDialog(val) {
			if (val == '日志') {
				this.logActiveName = '2';
				for (let k in this.logForm) {
					this.logForm[k] = '';
				}
			} else if (val == '标定') {
				for (let k in this.certificate) {
					this.certificate[k] = '';
				}
			}
		},
		// 表格编辑
		tableEditor(row) {
			this.tableAdd = false;
			this.dialogTable = true;
		},
		// 打开详情抽屉
		openDrawer(row) {
			// console.log(row)
			this.detailsTitle = row.dno;
			for (let k in this.detailsInfo) {
				if (row[k] != undefined) {
					this.detailsInfo[k] = row[k]
				}else {
					this.detailsInfo[k] = ''
				}
			}
			this.detailsId = row.id;
			this.getLogData();
			this.detailsDrawer = true;
		},
		// 右侧图标打开详情抽屉
		iconClick() {
			this.detailsDrawer = true;
		},
		// 详情tabs切换事件
		handleClick(tab, event) {

		},
		// 日志tabs切换事件
		logHandleClick(tab, event) {

		},
		// 格式化表格
		formatterTable(row, column) {
			// // console.log(column)
			// // console.log(row);
			// return
			let txt = ''
			if (column['property'] == 'area') {
				txt = this.formatterArea(row[column['property']]);
			} else if (column['property'] == 'status') {
				// status = 1 停用  2 故障  0 判断pid =0 空闲 ！= 0使用中
				let val = row[column['property']];
				txt = this.useStatus[val - 1];
			} else if (column['property'] == 'ste') {

				let ste = row[column['property']];
				txt = this.calibrationJudge(ste);
			}

			return txt;
		},
		// 格式化地域显示
		formatterArea(val) {
			var reg = new RegExp(',', 'g');
			var test = val.replace(reg, ' / ');
			return test
		},
		// 标定状态判断
		calibrationJudge(ste) {
			let txt = '';
			// let calibrationDate = new Date(term).getTime();
			// let intervalDate = 24 * 60 * 60 * 1000 * 31;
			// let difference = calibrationDate - this.milliseconds;
			txt = this.calibrationStatus[ste - 1];
			return txt;
		},
		// 大图预览关闭函数
		closeViewer() {
			this.showViewer = false;
			this.srcList = [];
		},
		// 单元格样式设置
		cellStyle({
			row,
			column,
			rowIndex,
			columnIndex
		}) {
			// // console.log(column)
			let txt = column['property']
			if (column['label'] == '标定状态') {
				let val = row[txt];
				// if()
				let text = this.calibrationJudge(val);
				if (text == '即将到期') {
					return "color: #E6A23C"
				} else if (text == '已过期') {
					return "color: #F56C6C"
				}
			}
		},
		// 统计
		getSummaries(param) {
			const {
				columns,
				data
			} = param;
			const sums = [];
			const sumsTxt = [];
			this.tableSummary = [];
			columns.forEach((column, index) => {
				if (index === 0) {
					sums[index] = '统计';
					this.tableSummary[index] = '统计';
					return;
				}
				let k = column.property;
				if (k == 'cno' || k == 'sno' ||
					k == 'area' || k == 'pjname' || k == 'term') {
					k = '';
				} else if (k == 'dno') {
					k = 'equipmentnum'
				}
				const values = data.map(item => Number(item[k]));
				if (!values.every(value => isNaN(value))) {
					if (k == 'ste') {
						let expired = 0; //已过期
						let aboutToExpire = 0; // 即将到期
						let normal = 0; // 正常
						values.forEach((i) => {
							if (i == 1) {
								normal += 1;
							} else if (i == 2) {
								aboutToExpire += 1;
							} else if (i == 3) {
								expired += 1;
							}
						})
						let txt = `<p class="expired">
								<span class="name">已过期:</span>
								<span class="num">${expired}台</span>
							</p>
							<p class="noOverdue">
								<span class="name">即将到期:</span>
								<span class="num">${aboutToExpire}台</span>
							</p>
							<p>
								<span class="name">正常:</span>
								<span class="num">${normal}台</span>
							</p>`;
						this.tableSummary[index] = `已过期：${expired}台；即将到期：${aboutToExpire}台；正常：${normal}台`;
						sums[index] = '';
						sumsTxt[index] = txt;
					} else if (k == 'equipmentnum') {
						sums[index] = values.reduce((prev, curr) => {
							// // console.log(prev,curr)
							const value = curr;
							if (!isNaN(value)) {
								var n = prev + curr;
								return n;
							} else {
								return prev;
							}
						}, 0) + '台';
						this.tableSummary[index] = sums[index];
						sumsTxt[index] = sums[index];
					} else if (k == 'status') {
						let use = 0; // 使用中
						let free = 0; // 空闲
						let fault = 0; // 故障
						let disable = 0; // 停用
						values.forEach((i) => {
							if (i == 1) {
								fault += 1;
							} else if (i == 2) {
								free += 1;
							} else if (i == 3) {
								use += 1;
							} else if (i == 4) {
								disable += 1;
							}
						});
						let txt = `<p class="fault">
								<span class="name">故障:</span>
								<span class="num">${fault}台</span>
							</p>
							<p class="free">
								<span class="name">空闲:</span>
								<span class="num">${free}台</span>
							</p>
							<p class="use">
								<span class="name">使用中:</span>
								<span class="num">${use}台</span>
							</p>
							<p class="disable">
								<span class="name">停用:</span>
								<span class="num">${disable}台</span>
							</p>`;
						this.tableSummary[index] = `故障：${fault}台；空闲：${free}台；使用中：${use}台；停用：${disable}台`;
						sums[index] = '';
						sumsTxt[index] = txt;
					}
				} else {
					this.tableSummary[index] = '';
					sums[index] = '';
					sumsTxt[index] = '';
				}
			});
			this.$nextTick(function() {
				let obj = $('#prject-table .el-table__footer-wrapper td .cell');
				sumsTxt.forEach((i, index) => {
					obj.eq(index).html(i);
				})
			})
			// // console.log(sums)
			return sums;
		}
	},
	components: {
		uploadImg,
		ElImageViewer
	},
	beforeDestroy() {},
	watch: {
		tableData: function(nv) {
			this.maxHeight = $('#prject-table').height();
			// this.$refs.table.doLayout()
		},
		tableSearchData: function(nv){
			this.$nextTick(function() {
				this.summary = true;
			})
		}
	}
};
