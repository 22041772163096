export default () => {
	return {
		searchInfo: {
			equipmentNumber: '',
			equipment: '',
			useStatus: null,
			calibrationStatus: null,
			project: '',
		},
		tableData: [],
		tableSearchData: [],//列表搜索后的数据
		summary: false,//合计行是否显示
		maxHeight: null,//表格最大高度
		projectSelectList: [],// 项目可选择列表
		useStatus: ['故障', '空闲', '使用中', '停用'], // 使用状态数组
		calibrationStatus: ['正常', '即将到期', '已过期'], // 标定状态数组
		milliseconds: '', // 表格数据请求时间毫秒数
		tableSummary: [], // 表格统计数据
		detailsDrawer: false, // 详情抽屉是否打开
		detailsInfo: {
			dno: '',
			sno: '',
			ste: '',
			cno: '',
			term: '',
			status: '',
			area: '',
			pjname: '',
			pid: '',
		},
		detailsTitle: '', // 详情抽屉名称
		detailsId: '', //抽屉详情数据id
		activeName: '1', // 详情tabs选中
		dialogTable: false, // 新增表格数据弹窗
		tableAdd: true, // 是否是表格新增数据
		tableForm: {

		}, //表格新增、编辑数据
		dialogLog: false, // 新增日志数据弹窗
		logData: [],//日志数据
		logAdd: true, // 是否是日志新增数据
		logForm: {
			keepers: '', // 保管人员
			date: '', // 使用日期
			reason: '', // 使用原由
			equipmentState: '', // 设备状态
			relevantPersonnel: '', // 相关人员
			confirmPersonnel: '', // 确认人员
			// confirmDate: '', // 确认日期
			msg: ''// 日志内容
		}, //日志新增、编辑数据
		certificate: {
			serialNumber: '',// 校准证书编号
			// periodOfValidity: '',// 有效期
			years: '',// 年
			month: '',// 月
			day: '01',// 日
			id: '',
		},//设备校准证书信息
		dialogCalibration: false,// 标定弹窗是否显示
		logTitle: ['keepers','date','reason','equipmentState','relevantPersonnel','confirmPersonnel'],//日志展示标题
		logActiveName: '2',// 日志tabs选中
		configuration1: {
			limit: 1, //最大文件个数
			name: 'file1' //上传文件组件的样式名字(多个组件同时存在时，该参数不能重复)
		},
		configuration2: {
			limit: 1, //最大文件个数
			name: 'file2' //上传文件组件的样式名字(多个组件同时存在时，该参数不能重复)
		},
		showViewer: false,// 大图预览是否显示
		srcList: [],// 大图预览图片数据
	}
}
